import React from "react";

class IconButton extends React.Component {
  render() {
    const { containerClass, iconClass, label } = this.props;
    return (
      <button type="button" class={containerClass}>
        <i class={iconClass}></i>
        {label}
      </button>
    );
  }
}
export default IconButton;
