import { css } from "styled-components";

import bootstrapStyles from "./bootstrap.min.css";
import bootstrapSelectStyles from "./bootstrap-select.min.css";
import lineAwesomeStyles from "./line-awesome.css";
import owlThemeStyles from "./owl.theme.default.min.css";
import fancyboxStyles from "./jquery.fancybox.min.css";
import daterangepickerStyles from "./daterangepicker.css";
import animatedHeadlineStyles from "./animated-headline.css";
import customStyles from "./style.css";
import additionalStyles from "./sass/style.scss";
import owlCarouselStyles from "./owl.carousel.min.css";
import jqueryUiStyles from "./jquery-ui.css";
import customReactDatePicker from "./custom-react-datepicker.css";
// import flagIconStyles from "./flag-icon.min.css";
const globalStyles = css`
  ${bootstrapStyles}
  ${bootstrapSelectStyles}
  ${lineAwesomeStyles}
  ${owlThemeStyles}
  ${fancyboxStyles}
  ${daterangepickerStyles}
  ${animatedHeadlineStyles}
  ${customStyles}
  ${additionalStyles}
  ${owlCarouselStyles}
  ${jqueryUiStyles}
  ${customReactDatePicker}
`;

// ${flagIconStyles}
export default globalStyles;
