import React from "react";

class InputBox extends React.Component {
  render() {
    const { label, children } = this.props;
    return (
      <div class="input-box">
        <label class="label-text">{label}</label>
        <div class="form-group">{children}</div>
      </div>
    );
  }
}
export default InputBox;
