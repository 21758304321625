import React from "react";
import QuantitySelector from "./QuantitySelector";
class GuestPickerDropdownItem extends React.Component {
  render() {
    const { selectBoxMap, label, error, onUpdateSelectBoxMap } = this.props;
    return (
      <React.Fragment>
        <div class="dropdown-item">
          <QuantitySelector
            selectBoxMap={selectBoxMap}
            label={label}
            error={error}
            onUpdateSelectBoxMap={onUpdateSelectBoxMap}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default GuestPickerDropdownItem;
