import React from "react";
import IconButton from "./IconButton";

class Pagination extends React.Component {
  render() {
    return (
      <div className="btn-box mt-4 text-center">
        <IconButton
          containerClass="theme-btn"
          iconClass="la la-refresh mr-1"
          label="Load More"
        />
        <p className="font-size-13 pt-2">Showing 1 - 5 of 124 Rooms</p>
      </div>
    );
  }
}
export default Pagination;
