import React from "react";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import PathConstants from "../../../routes/pathConstants";
import IconLinkItem from "../../../components/commons/IconLinkItem";
class Footer extends React.Component {
  render() {
    return (
      <section class="footer-area section-bg padding-top-40px padding-bottom-30px">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-8">
              <div class="term-box footer-item">
                <ul class="list-items list--items d-flex align-items-center">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Help Center</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="footer-social-box text-right">
                <ul class="social-profile">
                  <IconLinkItem iconClass="lab la-facebook-f" />
                  <IconLinkItem iconClass="lab la-twitter" />
                  <IconLinkItem iconClass="lab la-instagram" />
                  <IconLinkItem iconClass="lab la-linkedin-in" />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="section-block mt-4 mb-5"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 responsive-column">
              <div class="footer-item">
                <div class="footer-logo padding-bottom-30px">
                  <a href="index.html" class="foot__logo">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <p class="footer__desc">Diamond Hotel Kipe</p>
                <ul class="list-items pt-3">
                  <li>
                    Kipe, Carrefour Metal Guinee
                    <br />
                    Commune De Ratoma - Conakry - Republic de Guinee
                  </li>
                  <li>+224 629 69 69 44</li>
                  <li>
                    <a href="#">reservation@diamondhotelkipe.com</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 responsive-column">
              <div class="footer-item">
                <h4
                  class="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  Quick links
                </h4>
                <ul class="list-items list--items">
                  <li>
                    <Link to={PathConstants.ROOM_LIST}>Rooms</Link>
                  </li>
                  <li>
                    <Link to={PathConstants.CONFERENCE_FACILITY_DETAILS}>
                      Conference
                    </Link>
                  </li>
                  <li>
                    <Link to={PathConstants.GALLERY}>Gallery</Link>
                  </li>
                  <li>
                    <Link to={PathConstants.CONTACT}>Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 responsive-column">
              <div class="footer-item">
                <h4
                  class="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  Payment Methods
                </h4>
                <p class="footer__desc pb-3">
                  Pay any way you choose, we support all payment options.
                </p>
                <img src="images/payment-img.png" alt="" />
              </div>
            </div>
          </div>

          <div class="section-block"></div>
          <div class="row">
            <div class="col-lg-12">
              <div class="copy-right padding-top-30px text-center">
                <p class="copy__desc">
                  &copy; Copyright Diamond Hotel Kipe 2023. Made with
                  <span class="la la-heart"></span> by
                  <a href="https://themeforest.net/user/techydevs/portfolio">
                    Dvelge Lab
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
