import React from "react";
import InputBox from "./InputBox";
import ErrorNotification from "../page-elements/ErrorNotification";
class TextInput extends React.Component {
  render() {
    const {
      label,
      iconClass,
      type,
      name,
      value,
      placeholder,
      onChange,
      error,
    } = this.props;
    return (
      <React.Fragment>
        <InputBox label={label}>
          <span class={iconClass}></span>
          <input
            class="date-range form-control"
            type={type}
            name={name}
            value={value}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        </InputBox>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default TextInput;
