import React, { Suspense } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { LoadingIndicator } from "../../components/commons";

class MainLayout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Suspense
          fallback={
            <LoadingIndicator
              preloaderClass="preloader"
              loaderClass="full-page-loader"
              spinnerClass="spinner__path--full-page"
            />
          }
        >
          <main>
            <Outlet />
          </main>
        </Suspense>
        <Footer />
      </React.Fragment>
    );
  }
}
export default MainLayout;
