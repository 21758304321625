import React from "react";
import "./App.css";
import GlobalStyle from "./assets/styles/GlobalStyles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Page404 from "./pages/page-404";
import routes from "./routes";
function App() {
  const router = createBrowserRouter([
    {
      element: (
        <React.Fragment>
          <GlobalStyle /> <MainLayout />
        </React.Fragment>
      ),
      errorElement: <Page404 />,
      children: routes,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
