import React from "react";
import DatePicker from "react-datepicker";
import InputBox from "./InputBox";
import "react-datepicker/dist/react-datepicker.css";
import ErrorNotification from "../page-elements/ErrorNotification";
class DatePickerInput extends React.Component {
  render() {
    const {
      label,
      iconClass,
      selected,
      onChange,
      placeholderText,
      error,
      ...rest
    } = this.props;
    return (
      <React.Fragment>
        <InputBox label={label}>
          <span class={iconClass}></span>
          <DatePicker
            className="form-control"
            selected={selected}
            onChange={onChange}
            placeholderText={placeholderText}
            {...rest}
          ></DatePicker>
        </InputBox>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
const MemoizedDatePickerInput = React.memo(DatePickerInput);
export default MemoizedDatePickerInput;
