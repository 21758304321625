import React from "react";
class CheckboxWithLabel extends React.Component {
  render() {
    const { id, name, label, value } = this.props;
    return (
      <div className="custom-checkbox">
        <input type="checkbox" name={name} id={id} value={value} />
        <label
          htmlFor={id}
          className="d-flex justify-content-between align-items-center"
        >
          {label}
          <span className="text-black font-weight-regular">
            {`$${value}`}/ per night
          </span>
        </label>
      </div>
    );
  }
}
export default CheckboxWithLabel;
