import React from "react";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import PathConstants from "../../../routes/pathConstants";
class Header extends React.Component {
  render() {
    return (
      <header class="header-area">
        <div class="header-top-bar padding-right-100px padding-left-100px">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="header-top-content">
                  <div class="header-left">
                    <ul class="list-items">
                      <li>
                        <a href="#">
                          <i class="la la-phone mr-1"></i>+224 629 69 69 44
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="la la-envelope mr-1"></i>
                          reservation@diamondhotelkipe.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-menu-wrapper padding-right-100px padding-left-100px">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="menu-wrapper justify-content-between">
                  <a href="#" class="down-button">
                    <i class="la la-angle-down"></i>
                  </a>
                  <div class="logo">
                    <a href="index.html">
                      <img src={logo} alt="logo" />
                    </a>
                    <div class="menu-toggler">
                      <i class="la la-bars"></i>
                      <i class="la la-times"></i>
                    </div>
                  </div>

                  <div class="main-menu-content pr-0 ml-0">
                    <nav>
                      <ul>
                        <li>
                          <Link to={PathConstants.HOME}>Home</Link>
                        </li>
                        <li>
                          <Link to={PathConstants.ROOM_LIST}>Rooms</Link>
                        </li>
                        <li>
                          <Link to={PathConstants.GALLERY}>Gallery</Link>
                        </li>
                        <li>
                          <Link to={PathConstants.CONTACT}>Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
