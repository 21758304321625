import React from "react";
class LoadingIndicator extends React.Component {
  render() {
    const {
      preloaderClass = undefined,
      loaderClass,
      spinnerClass,
    } = this.props;
    return (
      <div className={preloaderClass} id={preloaderClass}>
        <div className={loaderClass}>
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className={`path ${spinnerClass}`}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }
}
export default LoadingIndicator;
