import React from "react";
import InputBox from "./InputBox";
import ErrorNotification from "../page-elements/ErrorNotification";
class TextAreaInputBox extends React.Component {
  render() {
    const { label, iconClass, name, value, placeholder, onChange, error } =
      this.props;
    return (
      <React.Fragment>
        <InputBox label={label}>
          <span class={iconClass}></span>
          <textarea
            class="message-control form-control"
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />
        </InputBox>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default TextAreaInputBox;
