import React from "react";
import SimpleGuestPickerItem from "./SimpleGuestPickerItem";
import ErrorNotification from "../page-elements/ErrorNotification";
class SimpleGuestPicker extends React.Component {
  render() {
    const { categories, selectBoxMap, error, onUpdateSelectBoxMap } =
      this.props;
    return (
      <React.Fragment>
        <div class="sidebar-widget-item">
          {categories.map((category, key) => {
            return (
              <SimpleGuestPickerItem
                key={key}
                label={category.label}
                span={category.span}
                selectBoxMap={selectBoxMap}
                error={error}
                onUpdateSelectBoxMap={onUpdateSelectBoxMap}
              />
            );
          })}
        </div>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default SimpleGuestPicker;
