import React from "react";
import { Link } from "react-router-dom";
class LinkButton extends React.Component {
  render() {
    const { containerClass, theme, to, label } = this.props;
    return (
      <div class={containerClass}>
        <Link class={theme} to={to}>
          {label}
        </Link>
      </div>
    );
  }
}

export default LinkButton;
