import React, { createRef } from "react";
import GuestPickerDropdownItem from "./GuestPickerDropdownItem";
import InputBox from "./InputBox";
import ErrorNotification from "../page-elements/ErrorNotification";
class DropdownGuestPicker extends React.Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
  }
  getSelectBoxTitle = () => {
    return Object.entries(this.props.selectBoxMap)
      .map(([key, value]) => `${value} ${key}`)
      .join(" - ");
  };

  componentDidMount() {
    if (!this.props.categories) {
      return {};
    }
  }

  getInitialSelectBoxMap = () => {
    if (!this.props.categories) {
      return {};
    }
    return this.props.categories.reduce((acc, category) => {
      acc[category.label] = 0;
      return acc;
    }, {});
  };

  render() {
    const { label, categories, isDropdownOpen, handleToggleDropdown, error } =
      this.props;
    return (
      <React.Fragment>
        <InputBox label={label}>
          <div className="dropdown dropdown-contain gty-container">
            <a
              className="dropdown-toggle dropdown-btn"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={handleToggleDropdown}
            >
              {this.getSelectBoxTitle()}
            </a>

            <div
              className="dropdown-menu dropdown-menu-wrap"
              style={{ display: isDropdownOpen ? "block" : "none" }}
            >
              {categories.map((category, index) => {
                return (
                  <GuestPickerDropdownItem
                    key={index}
                    label={category.label}
                    inputType={category.inputType}
                    selectBoxMap={this.props.selectBoxMap}
                    onUpdateSelectBoxMap={this.props.onUpdateSelectBoxMap}
                  />
                );
              })}
            </div>
          </div>
        </InputBox>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default DropdownGuestPicker;
