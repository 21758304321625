const PathConstants = {
  HOME: "/",
  ROOM_LIST: "/rooms",
  ROOM_DETAILS: "/room-details",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  BOOKING_SUBMISSION: "/booking-submission",
  BOOKING_COMPLETED: "/booking-completed",
  INVOICE_DISPLAY: "/invoice-display",
};
export default PathConstants;
