import React from "react";
class QuantitySelector extends React.Component {
  handleQtyChangerClicked = (type) => {
    const { label, onUpdateSelectBoxMap, selectBoxMap } = this.props;
    const newQuantity =
      type === "Inc"
        ? selectBoxMap[label] + 1
        : Math.max(0, selectBoxMap[label] - 1);

    onUpdateSelectBoxMap(label, newQuantity);
  };
  render() {
    const { selectBoxMap, label, span, error } = this.props;
    return (
      <div class="qty-box d-flex align-items-center justify-content-between">
        <label>
          {label} {span && <span>{span}</span>}
        </label>
        <div className="qtyBtn d-flex align-items-center">
          <div
            className="qtyDec"
            onClick={() => this.handleQtyChangerClicked("Dec")}
          >
            <i className="la la-minus"></i>
          </div>
          <input
            type="text"
            name={`${label}_number`}
            value={selectBoxMap[label]}
            readOnly
          />
          <div
            className="qtyInc"
            onClick={() => this.handleQtyChangerClicked("Inc")}
          >
            <i className="la la-plus"></i>
          </div>
        </div>
      </div>
    );
  }
}
export default QuantitySelector;
