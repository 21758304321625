import React from "react";
import OwlCarousel from "react-owl-carousel2";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }
  render() {
    const { className, children, customOptions } = this.props;
    const defaultOptions = {
      loop: true,
      nav: true,
      dots: true,
      smartSpeed: 700,
      autoplay: false,
      margin: 30,
      navText: [
        '<i class="la la-angle-left"></i>',
        '<i class="la la-angle-right"></i>',
      ],
    };
    const options = {
      ...defaultOptions,
      ...customOptions,
    };
    return (
      <OwlCarousel
        ref={this.carouselRef}
        options={options}
        className={className}
      >
        {children}
      </OwlCarousel>
    );
  }
}

export default Carousel;
