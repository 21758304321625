import React from "react";

class Map extends React.Component {
  render() {
    return (
      <section class="map-area padding-bottom-100px">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="map-container">
                <div id="map"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Map;
