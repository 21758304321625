import React from "react";
import PathConstants from "./pathConstants";

const FinancialDocumentViewer = React.lazy(() =>
  import("../pages/financial-document-viewer")
);
const Home = React.lazy(() => import("../pages/home"));
const RoomList = React.lazy(() => import("../pages/room-list"));
const RoomDetails = React.lazy(() => import("../pages/room-details"));
const Gallery = React.lazy(() => import("../pages/gallery"));
const Contact = React.lazy(() => import("../pages/contact"));
const BookingSubmission = React.lazy(() =>
  import("../pages/booking-submission")
);

const BookingCompleted = React.lazy(() => import("../pages/booking-completed"));
const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.ROOM_LIST, element: <RoomList /> },
  {
    path: PathConstants.ROOM_DETAILS,
    element: <RoomDetails />,
  },
  { path: PathConstants.GALLERY, element: <Gallery /> },
  { path: PathConstants.CONTACT, element: <Contact /> },
  { path: PathConstants.BOOKING_SUBMISSION, element: <BookingSubmission /> },
  { path: PathConstants.BOOKING_COMPLETED, element: <BookingCompleted /> },
  { path: PathConstants.INVOICE_DISPLAY, element: <FinancialDocumentViewer /> },
];

export default routes;
