import React from "react";

class IconFeatureCard extends React.Component {
  render() {
    const { iconClass, featureTitle } = this.props;
    return (
      <div class="col-lg-4 responsive-column">
        <div class="single-tour-feature d-flex align-items-center mb-3">
          <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
            <i class={`la la-${iconClass}`}></i>
          </div>
          <div class="single-feature-titles">
            <h3 class="title font-size-15 font-weight-medium">
              {featureTitle}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
export default IconFeatureCard;
