import React from "react";
import ErrorNotification from "../page-elements/ErrorNotification";
class CheckBoxInput extends React.Component {
  render() {
    const { label, name, value, onChange, error } = this.props;
    return (
      <React.Fragment>
        <div class="input-box">
          <div class="form-group mb-0">
            <div class="custom-checkbox mb-0">
              <input
                type="checkbox"
                id={name}
                name={name}
                value={value}
                onChange={onChange}
              />
              <label for={name}>{label}</label>
            </div>
          </div>
        </div>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default CheckBoxInput;
