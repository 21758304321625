import React from "react";
import Carousel from "./Carousel";
class FullWidthSliderCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }
  render() {
    const customOptions = {
      center: true,
      items: 2,
      nav: true,
      dots: false,
      loop: true,
      margin: 10,
      smartSpeed: 500,
      navText: [
        '<i class="la la-long-arrow-left"></i>',
        '<i class="la la-long-arrow-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          autoplay: true,
        },
        576: {
          items: 2,
        },
      },
    };
    return (
      <Carousel
        className="full-width-slider carousel-action owl-loaded owl-carousel"
        customOptions={customOptions}
      >
        {this.props.children}
      </Carousel>
    );
  }
}
export default FullWidthSliderCarousel;
