import React from "react";
import QuantitySelector from "./QuantitySelector";
class SimpleGuestPickerItem extends React.Component {
  render() {
    const { selectBoxMap, label, span, error, onUpdateSelectBoxMap } =
      this.props;
    return (
      <div class="sidebar-widget-item">
        <QuantitySelector
          selectBoxMap={selectBoxMap}
          label={label}
          span={span}
          error={error}
          onUpdateSelectBoxMap={onUpdateSelectBoxMap}
        />
      </div>
    );
  }
}
export default SimpleGuestPickerItem;
