import React from "react";
import InputBox from "./InputBox";
import ErrorNotification from "../page-elements/ErrorNotification";
class SelectInput extends React.Component {
  render() {
    const { label, name, value, options, onChange, error } = this.props;
    return (
      <React.Fragment>
        <InputBox label={label}>
          <div class="select-contain w-auto">
            <select
              class="select-contain-select form-control"
              name={name}
              id={name}
              value={value}
              onChange={onChange}
            >
              {options &&
                options.map((option, key) => {
                  return (
                    <option
                      key={key}
                      class="dropdown-item"
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  );
                })}
            </select>
          </div>
        </InputBox>
        {error && <ErrorNotification errorMessage={error} />}
      </React.Fragment>
    );
  }
}
export default SelectInput;
