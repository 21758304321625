import React from "react";
class Button extends React.Component {
  render() {
    const { containerClass, theme, label, ...rest } = this.props;
    return (
      <div className={containerClass}>
        <button type="submit" className={theme} {...rest}>
          {label}
        </button>
      </div>
    );
  }
}
export default Button;
