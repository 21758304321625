import React from "react";
class IconElement extends React.Component {
  render() {
    const { iconClass, elementTitle } = this.props;
    return (
      <li>
        <i class={iconClass}></i>
        {elementTitle && elementTitle}
      </li>
    );
  }
}
export default IconElement;
