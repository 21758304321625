import React from "react";

class IconLinkItem extends React.Component {
  render() {
    const { iconClass } = this.props;
    return (
      <li>
        <a href="#">
          <i class={iconClass}></i>
        </a>
      </li>
    );
  }
}
export default IconLinkItem;
